import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const PerfectForYouSection = styled.section`

  .react-multi-carousel-dot-list {

    .react-multi-carousel-dot {

      button {
        background: ${orange.base};
      }
    }
  }
`

export const Card = styled.div`
  border-radius: 12px;
  min-height: 235px;
  padding: 24px;

  @media ${device.desktopLG} {
    max-width: 264px;
    min-height: 205px;
  }

  h3 {
    font-family: "Inter", Helvetica, sans-serif !important;
  }
`

export const CardDesktopDiv = styled.div`
  gap: 24px;

  h3 {
    font-family: "Inter", Helvetica, sans-serif !important;
  }
`
