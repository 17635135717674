import { breakpoints, device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled, { css, keyframes } from 'styled-components'

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
type ContainerProps = {
  isModal?: () => void;
}

type BackgroundColor = {
  bgColor: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${white};
  font-family: "Sora", Helvetica, sans-serif;
  height: 100vh;

  input {
    font-family: "Inter", Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6A6C72;
  }

  img {
    max-width: 204px;
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
      border-radius: 16px;
      padding: 50px 30px 40px;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;
      margin-top: 35vh;
      max-width: 312px;
      max-height: 344px;
      padding: 24px 20px;

      p {
        font-family: "Inter", Helvetica, sans-serif;
      }
  
      @media (min-width: ${breakpoints.md}) {
        margin-top: 35vh;
        max-width: 360px;
        max-height: 344px;
      }
    `
  )}
  
  .form--default {   
    label {
      color: ${grayscale['400']};
    }
  }

  .btn {
    height: 40px;
    @media ${device.tablet} {
      min-width: 100%;
      height: 48px;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;
`

export const BackgroundIcon = styled.div<BackgroundColor>`
  background-color: ${(props: BackgroundColor) => props.bgColor};
  width: 64px;
  height: 64px;
  border-radius: 50%;

  svg {
    width: 32px;
    height: 32px;
  }
`
