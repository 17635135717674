import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

import BackgroundLG from 'src/pages/pra-voce/financiamento-imobiliario/residencial/assets/images/background-financie-no-inter-e-aproveite-residencial-cartao-black-lg.png'
import BackgroundMD from 'src/pages/pra-voce/financiamento-imobiliario/residencial/assets/images/background-financie-no-inter-e-aproveite-residencial-cartao-black-md.png'
import BackgroundXL from 'src/pages/pra-voce/financiamento-imobiliario/residencial/assets/images/background-financie-no-inter-e-aproveite-residencial-cartao-black-xl.png'

export const SuaCasaNovaSection = styled.section`
  background-image: none;
  
  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
    min-height: 580px;
    background-position: right top;
    background-size: initial;
    background-repeat: no-repeat;
  }

  @media ${device.desktopLG} {
    min-height: 532px;
    background-image: url(${BackgroundLG});
  }

  @media ${device.desktopXL} {
    min-height: 675px;
    background-image: url(${BackgroundXL});
  }

  .btn-orange--extra {
    font-size: 14px;
    font-weight: 700;
    @media ${device.desktopLG} {
      height: 40px;
      min-width: 418px;
    }
    @media ${device.desktopXL} {
      min-width: 418px !important;
    }
  }

  h3 {
    font-family: "Inter", Helvetica, sans-serif;
  }
`
