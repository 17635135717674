import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, red, white } from 'src/styles/colors'
import styled from 'styled-components'

import BgLg from '../../assets/images/bg-lg.png'
import Bg from '../../assets/images/bg-mobile.png'
import BgTablet from '../../assets/images/bg-tablet.png'
import BgXl from '../../assets/images/bg-xl.png'

export const Section = styled.section`
  background: url(${Bg});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 508px;

  @media (min-width: ${breakpoints.md}) {
    background: url(${BgTablet});
    background-repeat: no-repeat;
    min-height: 281px;
    background-size: cover;
  }

  @media (min-width: ${breakpoints.lg}) {
    background: url(${BgLg});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 387px;
  }

  @media (min-width: ${breakpoints.xl}) {
    background: url(${BgXl});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 536px;
  }

  @media (min-width: ${breakpoints.xxl}) {
    background: url(${BgXl});
    background-repeat: no-repeat;
    background-size: cover;
  }

  .space {
    @media (min-width: ${breakpoints.md}) {
      padding-top: 104px;
    }
    @media (min-width: ${breakpoints.lg}) {
      padding-top: 110px;
    }
    @media (min-width: ${breakpoints.xl}) {
      padding-top: 120px;
      padding-right: 88px !important;
      padding-left: 88px !important;
    }
    @media (min-width: ${breakpoints.xxxl}) {
      padding-right: 230px !important;
    }
  }
`

export const Card = styled.div`
  width: 100%;
  border: 2px solid ${grayscale[200]};
  border-radius: 38px;
  background-color: ${white};
  padding: 17px 22px ;

  @media (min-width: ${breakpoints.md}) {
    padding: 16px 22px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 24px 30px;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 24px 47px;
  }

  .range-slider {
    @media (min-width: ${breakpoints.lg}) {
      padding-bottom: 12px;
    }
  }

  .bar {
    background-color: ${grayscale[200]} !important;
  }

  .btn-utm {
    width: 100%;
    height: 48px;
    background: ${orange.extra};
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: ${white};
    max-width: 100% !important;
    border: none;
    text-transform: initial !important;

    &:hover,
    &:focus {
      outline: none;
      text-decoration: none;
      color: ${white};
    }

    &:disabled {
      background: ${grayscale[300]} !important;

      &:hover {
        background: ${grayscale[300]} !important;
      }
    }
  }

  .text-error {
    color: ${red[500]};
  }
`
export const Button = styled.button`
  width: 100%;
  height: 48px;
`
export const Input = styled.input`
  font-family: "Sora", Helvetica, sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  color: ${grayscale[500]};
  width: 100%;
  max-width: 250px;
  border: none;

  &:focus {
    outline: none;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 40px;
    line-height: 50px;
  }
`
