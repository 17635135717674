import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const PortabilityStepSection = styled.section`
  .step-with-border {
    border-bottom: 1px solid ${grayscale[200]};
    
    @media ${device.tablet} {
      border-bottom: 0;
    }
  }
  .btn {
    height: 40px;
    @media ${device.desktopXL} {
      max-width: 366px;
    }
  }

  h3 {
    font-family: "Inter", Helvetica, sans-serif !important;
  }
`

export const PortabilityStepNumber = styled.div`
  background: ${orange.extra};
  border-radius: 50%;
  margin-right: 24px;
  min-width: 30px;
  text-align: center;
  padding: 8px;

  @media ${device.tablet} {
    margin-right: 8px;
  }
`
